import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './firebase';
import axios from 'axios';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; 

import {
  Box,
  Typography,
  Paper,
  Chip,
  CircularProgress,
  Container,
  AppBar,
  Toolbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './ListingDetailPage.css';

function ListingDetailPage() {
  const { id: listingId } = useParams();
  const [listing, setListing] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const doc = await db.collection('listings').doc(listingId).get();
        if (doc.exists) {
          const listingData = doc.data();
          setListing(listingData);

          // Check if assistantId and apiKey exist before starting the chat session
          if (listingData.apiKey && listingData.assistantId) {
            startChatSession(listingData.apiKey, listingData.assistantId);
          }
        } else {
          console.error('Listing not found');
        }
      } catch (error) {
        console.error('Error fetching listing:', error);
      }
    };

    const startChatSession = async (apiKey, assistantId) => {
      try {
        const response = await axios.post('https://agentivehub.com/api/chat/session', {
          api_key: apiKey,
          assistant_id: assistantId,
        });
        setSessionId(response.data.session_id);
      } catch (error) {
        console.error('Error starting chat session:', error);
      }
    };

    fetchListing();
  }, [listingId]);

  const handleSendMessage = async (message) => {
    if (!sessionId) {
      console.error('Chat session not initialized');
      return;
    }

    const newMessages = [...chatMessages, { content: message, sender: 'user' }];
    setChatMessages(newMessages);
    setIsTyping(true);

    try {
      const chatResponse = await axios.post('https://agentivehub.com/api/chat', {
        api_key: listing.apiKey,
        session_id: sessionId,
        type: 'custom_code',
        assistant_id: listing.assistantId,
        messages: [{ role: 'user', content: message }],
      });

      const botMessage = chatResponse.data.content;
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { content: botMessage, sender: 'bot' },
      ]);
      setIsTyping(false);
    } catch (error) {
      console.error('Chat error:', error);
      setIsTyping(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#ffffff', minHeight: '100vh', pb: 4 }}>
      {/* Header */}
      <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent', color: '#333', boxShadow: 'none', padding: '20px 0' }}>
        <Container>
          <Toolbar disableGutters>
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontWeight: 600,
                fontSize: '1.8rem',
                color: '#7C4DFF',
                letterSpacing: 1,
                fontFamily: '"Roboto", sans-serif',
                flexGrow: 1,
                textTransform: 'uppercase',
              }}
            >
              thatBazaar
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>

      <Container maxWidth="md">
        {listing ? (
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              marginTop: 4,
              borderRadius: 2,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            {/* Listing Image */}
            {listing.imageUrl && (
              <Box
                component="img"
                src={listing.imageUrl}
                alt={listing.title}
                sx={{
                  width: '100%',
                  height: 300,
                  objectFit: 'scale-down',
                  borderRadius: 2,
                  mb: 2,
                }}
              />
            )}
            {/* Title and Price */}
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333', mb: 1 }}>
              {listing.title}
            </Typography>
            <Chip
              label={`Price: ${listing.price} AED`}
              color="primary"
              sx={{
                fontSize: '1rem',
                fontWeight: '600',
                backgroundColor: '#7C4DFF',
                color: '#fff',
                mb: 2,
              }}
            />

            {/* Description */}
            <Typography variant="body1" sx={{ lineHeight: 1.6, color: '#666', mb: 2 }}>
              {listing.description}
            </Typography>
          </Paper>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
            <CircularProgress />
          </Box>
        )}

        {/* Conditional Chat Button */}
        {listing && listing.apiKey && listing.assistantId && (
          <Box
            onClick={() => setShowChat(!showChat)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              position: 'fixed',
              bottom: 20,
              right: 20,
              zIndex: 1000,
              backgroundColor: '#7C4DFF',
              color: '#fff',
              padding: '10px 15px',
              borderRadius: '30px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#5D33CC', // Subtle hover effect
              },
            }}
          >
            {showChat ? (
              <CloseIcon sx={{ color: '#fff' }} />
            ) : (
              <ShoppingCartIcon sx={{ color: '#fff' }} />
            )}
            <Typography variant="button" sx={{ color: '#fff', fontWeight: 'bold' }}>
              {showChat ? 'Close Chat' : 'Interested in Buying?'}
            </Typography>
          </Box>
        )}

        {/* Chat Container */}
        {showChat && (
          <Box className="chatbot" sx={{ position: 'fixed', bottom: 80, right: 20, zIndex: 1000 }}>
            <MainContainer>
              <ChatContainer>
                <MessageList typingIndicator={isTyping && <TypingIndicator content="" />}>
                  {chatMessages.map((msg, index) => (
                    <Message
                      key={index}
                      model={{
                        message: msg.content,
                        sentTime: 'just now',
                        sender: msg.sender === 'user' ? 'You' : 'Bot',
                        direction: msg.sender === 'user' ? 'outgoing' : 'incoming',
                      }}
                    />
                  ))}
                </MessageList>
                <MessageInput placeholder="Type a message" onSend={handleSendMessage} />
              </ChatContainer>
            </MainContainer>
          </Box>
        )}
      </Container>

      {/* Footer */}
      <Box sx={{ backgroundColor: '#F5F5F5', color: '#333', paddingY: 2, textAlign: 'center', borderTop: '1px solid #e0e0e0', marginTop: 4 }}>
        <Typography variant="body2" sx={{ fontSize: '0.85rem', color: '#666' }}>
          © 2024 thatBazaar. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default ListingDetailPage;
