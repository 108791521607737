// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCaO7tYoqCzW2lme8b8qWfqRNxBO2Fh6x8",
    authDomain: "thatbazaar-3fd05.firebaseapp.com",
    projectId: "thatbazaar-3fd05",
    storageBucket: "thatbazaar-3fd05.appspot.com",
    messagingSenderId: "343347897715",
    appId: "1:343347897715:web:3fbd17e7cb5bc80ae45106"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
export { db };
