import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  CircularProgress,
  AppBar,
  Toolbar,
  Container,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function ListingsPage() {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchListings = async () => {
      const snapshot = await db.collection('listings').orderBy('createdAt', 'desc').get();
      setListings(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setLoading(false);
    };
    fetchListings();
  }, []);

  const handleListingClick = (id) => {
    navigate(`/listing/${id}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#ffffff', minHeight: '100vh', paddingBottom: 4 }}>
      {/* Header */}
      <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent', color: '#333', boxShadow: 'none', padding: '20px 0' }}>
        <Container>
          <Toolbar disableGutters>
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontWeight: 600,
                fontSize: '1.8rem',
                color: '#7C4DFF',
                letterSpacing: 1,
                fontFamily: '"Roboto", sans-serif',
                flexGrow: 1,
                textTransform: 'uppercase',
              }}
            >
              thatBazaar
            </Typography>
            <IconButton color="inherit" sx={{ padding: 0 }}>
              <SearchIcon sx={{ fontSize: '28px' }} />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Content */}
      <Container sx={{ paddingY: 2 }}>
        <Grid container spacing={4}>
          {listings.map(listing => (
            <Grid item xs={12} key={listing.id}>
              <Card
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                  },
                }}
                onClick={() => handleListingClick(listing.id)}
              >
                {/* Image */}
                <CardMedia
                  component="img"
                  sx={{ width: 150, height: 'auto', objectFit: 'cover', borderRadius: '8px 0 0 8px' }}
                  image={listing.imageUrl || 'https://via.placeholder.com/150'}
                  alt={listing.title}
                />
                
                {/* Listing Details */}
                <CardContent sx={{ flex: '1 0 auto', padding: '16px 24px' }}>
                  <Typography variant="h6" component="div" sx={{ fontWeight: 500, color: '#333', fontSize: '1.2rem', lineHeight: 1.3 }}>
                    {listing.title}
                  </Typography>
                  {/* <Typography variant="body2" color="text.secondary" sx={{ marginTop: '8px', color: '#666', fontSize: '0.9rem' }}>
                    {listing.category} • {listing.subCategory}
                  </Typography> */}
                  <Typography variant="body2" color="text.secondary" sx={{ marginTop: '4px', fontSize: '0.85rem', color: '#999' }}>
                    {listing.location || 'Unknown Location'} • Posted on {new Date(listing.createdAt).toLocaleDateString()}
                  </Typography>
                  <Typography 
  variant="body2" 
  color="text.secondary" 
  sx={{
    marginTop: '8px', 
    color: '#666', 
    fontSize: '0.9rem', 
   
  }}
>
  {listing.description ? `${listing.description.slice(0, 120)}...` : 'No description available'}
</Typography>

                  <Typography variant="h6" color="primary" sx={{ marginTop: '16px', fontWeight: 600, color: '#7C4DFF' }}>
                    {listing.price} AED
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Footer */}
      <Box sx={{ backgroundColor: '#F5F5F5', color: '#333', paddingY: 2, textAlign: 'center', borderTop: '1px solid #e0e0e0', marginTop: 4 }}>
        <Typography variant="body2" sx={{ fontSize: '0.85rem', color: '#666' }}>
          © 2024 thatBazaar. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default ListingsPage;
