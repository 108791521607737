import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListingsPage from './ListingsPage';
import ListingDetailPage from './ListingDetailPage';
import './main.scss';


function App() {
  return (
    <Routes>
      <Route path="/" element={<ListingsPage />} />
      <Route path="/listing/:id" element={<ListingDetailPage />} />
    </Routes>
  );
}

export default App;
